import React from "react";
import { graphql } from "gatsby";
import { StructuredText } from "react-datocms";
import {
  renderRule,
  isHeading,
  isParagraph,
  isList,
} from "datocms-structured-text-utils";
import Paragraph from "@components/Paragraph";
import Title from "@components/Title";
import Layout from "@hoc/Layout";
import { StructuredList } from "@components/Structured";
import { StaticImage } from "gatsby-plugin-image";
import TrimPath from "@components/TrimPath";
import Seo from "@components/Seo";

export default function Policy({ pageContext, data }) {
  return (
    <Layout locale={pageContext.locale}>
      <Seo {...{ ...data.seo, lang: pageContext.locale }} />
      <section className="py-32 pb-48 overflow-hidden">
        <div className="w-11/12 mx-auto max-w-[850px] relative z-30">
          <Title
            {...{
              type: 1,
              color: "black",
            }}
          >
            {data.policy.pageName}
          </Title>
          <StructuredText
            data={data.policy.policyContent}
            customRules={[
              renderRule(isHeading, ({ node, children, key }) => (
                <Title
                  {...{
                    key,
                    type: node.level,
                    className: "",
                  }}
                >
                  {children}
                </Title>
              )),
              renderRule(isParagraph, ({ node, children, key }) => (
                <Paragraph
                  {...{
                    key,
                    color: "black",
                    className: "",
                  }}
                >
                  {children}
                </Paragraph>
              )),
              renderRule(isList, ({ node, children, adapter, key }) => (
                <StructuredList
                  {...{
                    node,
                    adapter,
                    key,
                    className: "max-w-3xl w-11/12 mx-auto mb-12 lg:mb-16",
                  }}
                >
                  {children}
                </StructuredList>
              )),
            ]}
          />
        </div>

        <TrimPath
          {...{
            name: "topLeft",
            className: "!top-[10%] opacity-50 lg:opacity-100",
          }}
        />

        <TrimPath
          {...{
            name: "topRight",
            className: "!top-[40%] !-right-24 opacity-50 lg:opacity-100",
          }}
        />

        <TrimPath
          {...{
            name: "bottomRight",
            className: "!bottom-[20%] opacity-50 lg:opacity-100",
          }}
        />

        <TrimPath
          {...{
            name: "bottomLeft",
            className: "!bottom-[10%] opacity-50 lg:opacity-100",
          }}
        />

        <StaticImage
          src="../assets/geolines/bkg-white-lines-left@2x.png"
          className="!absolute left-0 -bottom-0 max-w-[750px] 2xl:max-w-[950px] -z-10 opacity-75"
          width={650}
          alt="Geolines Graphic"
        />

        <StaticImage
          src="../assets/geolines/bkg-white-lines-right@2x.png"
          className="!absolute hidden lg:block right-0 -bottom-0 max-w-[400px] xl:max-w-[500px] -z-10 opacity-75"
          width={450}
          alt="Geolines Graphic"
        />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query getPolicyPage($id: String, $locale: String) {
    policy: datoCmsAnglerPolicyPage(id: { eq: $id }, locale: { eq: $locale }) {
      id
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      pageName
      pageSlug
      policyContent {
        value
        blocks
      }
    }
  }
`;
